import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AnimationController, NavController, Platform } from '@ionic/angular';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';

import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
// import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { Network } from '@ionic-native/network/ngx';
import { Downloader } from '@ionic-native/downloader/ngx';
// import { UniqueDeviceID } from '@ionic-native/unique-device-id/ngx';
import { Device } from '@ionic-native/device/ngx';

declare var $: any;
import { global } from './global';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    // private backgroundMode: BackgroundMode,
    private statusBar: StatusBar,
    private platform: Platform,
    private router: Router,
    private animationCtrl: AnimationController,
    private navCtrl: NavController,
    private location: Location,
    private fcm: FCM,
    private device: Device,
    private network: Network,
    private downloader: Downloader,
    private localNotifications: LocalNotifications,
    // private uniqueDeviceID: UniqueDeviceID,
    private androidPermissions: AndroidPermissions,
    private splashScreen: SplashScreen
  )
  {
    this.initializeApp();
  }

  AnimationOptions : string= '';
  sliderTwo: any;
  slideOptsTwo = {
    autoplay:true,
    on: {
      beforeInit() {
        const swiper = this;
        swiper.classNames.push(`${swiper.params.containerModifierClass}fade`);
        const overwriteParams = {
          slidesPerView: 1,
          slidesPerColumn: 1,
          slidesPerGroup: 1,
          watchSlidesProgress: true,
          spaceBetween: 0,
          virtualTranslate: true,
        };
        swiper.params = Object.assign(swiper.params, overwriteParams);
        swiper.params = Object.assign(swiper.originalParams, overwriteParams);
      },
      setTranslate() {
        const swiper = this;
        const { slides } = swiper;
        for (let i = 0; i < slides.length; i += 1) {
          const $slideEl = swiper.slides.eq(i);
          const offset$$1 = $slideEl[0].swiperSlideOffset;
          let tx = -offset$$1;
          if (!swiper.params.virtualTranslate) tx -= swiper.translate;
          let ty = 0;
          if (!swiper.isHorizontal()) {
            ty = tx;
            tx = 0;
          }
          const slideOpacity = swiper.params.fadeEffect.crossFade
            ? Math.max(1 - Math.abs($slideEl[0].progress), 0)
            : 1 + Math.min(Math.max($slideEl[0].progress, -1), 0);
          $slideEl
            .css({
              opacity: slideOpacity,
            })
            .transform(`translate3d(${tx}px, ${ty}px, 0px)`);
        }
      },
      setTransition(duration) {
        const swiper = this;
        const { slides, $wrapperEl } = swiper;
        duration = 500;
        slides.transition(duration);
        if (swiper.params.virtualTranslate && duration !== 0) {
          let eventTriggered = false;
          slides.transitionEnd(() => {
            if (eventTriggered) return;
            if (!swiper || swiper.destroyed) return;
            eventTriggered = true;
            swiper.animating = false;
            const triggerEvents = ['webkitTransitionEnd', 'transitionend'];
            for (let i = 0; i < triggerEvents.length; i += 1) {
              $wrapperEl.trigger(triggerEvents[i]);
            }
          });
        }
      },
    }
  }
  async tutuppopup(attr, animasi){
    var attrBtModal = attr;
    var animasi		  = animasi;
    if(animasi == 'zoom'){
      $("#"+attrBtModal).removeClass('besar');
    }
  }
  async iklanlink(attr){
    $("#iklan-mengambang").removeClass('besar');
    if(attr !== '#'){
    this.router.navigate(['info',attr, 'personal'], {replaceUrl: true});
    }
  }
  async keluarapk(){
    navigator['app'].exitApp();
  }
  async keluar(){
    $(".maaf-pengurus").removeClass("besar");
    this.navCtrl.navigateBack(['personal']);
  }
  async checkisBeginning(object, slideView) {
    slideView.isBeginning().then((istrue) => {
      object.isBeginningSlide = istrue;
    });
  }
  async checkIfNavDisabled(object, slideView) {
    this.checkisBeginning(object, slideView);
    this.checkisEnd(object, slideView);
  }
  async checkisEnd(object, slideView) {
    slideView.isEnd().then((istrue) => {
      object.isEndSlide = istrue;
    });
  }
  async SlideDidChange(object, slideView) {
    this.checkIfNavDisabled(object, slideView);
  }
  async tutupapk(){
    $.ajax({
      type: "get",
      url: global.apiURL+"deleteuuid",
      data: {
        member  : localStorage.getItem('memberid'),
        uuid    : this.device.uuid,
      },
      success: (obj)=>{
        localStorage.removeItem("hapusuuid");
      }
    });
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['home'], {replaceUrl: true});
  }

  initializeApp() {
    $(".bt-fechar, .bg_modal").click(function(e){
      if ($(e.target).is('.bt-fechar')) {
        var attrBtModal = $(this).data('btmodal');
      var animasi		= $(this).data('animasi');
      if(animasi == 'zoom'){
        $("#"+attrBtModal).removeClass('besar');
      }
      else if(animasi == 'move'){
        $("#"+attrBtModal).removeClass('atas');
      }
      else{
        $("#"+attrBtModal).removeClass('active');
      }
      }
      else if ($(e.target).is('.modal, .modal *')) {
        // $(this).click(false);
      }
      else{
      var attrBtModal = $(this).data('btmodal');
      var animasi		= $(this).data('animasi');
      if(animasi == 'zoom'){
        $("#"+attrBtModal).removeClass('besar');
      }
      else if(animasi == 'move'){
        $("#"+attrBtModal).removeClass('atas');
      }
      else{
        $("#"+attrBtModal).removeClass('active');
      }
      }
    });


    $.ajax({
      type: "get",
      url: global.apiURL+"iklan",
      data: { type: 2, member: localStorage.getItem('memberid') },
      success: (obj)=>{
        if(obj.page == 'sukses'){
          this.sliderTwo = obj.iklan;
          $("#iklan-mengambang .bt-fechar").attr('data-btmodal', 'iklan-mengambang');
          $("#iklan-mengambang .bt-fechar").attr('data-animasi', 'zoom');
          $("#iklan-mengambang").addClass('kecil');
          setTimeout(function(){
            $("#iklan-mengambang").removeClass('kecil');
            $("#iklan-mengambang").addClass('besar');
          }, 200);

          if(!$("#iklan-mengambang").hasClass("gaiso")){
            $("#iklan-mengambang").attr('data-btmodal', 'iklan-mengambang');
            $("#iklan-mengambang").attr('data-animasi', 'zoom');
          }
        }
      }
    });
    $(document).on("click", ".tolinkupdate", (event) => {
      $("#updateaplikasi").removeClass("besar");
      $.ajax({
        type: "get",
        url: global.apiURL+"sudahupdate",
        data: { member: localStorage.getItem('memberid') },
        success: (obj)=>{
          window.location.href = "https://play.google.com/store/apps/details?id=com.prowarga.satuins";
        }
      });
    });
    this.platform.ready().then(() => {
      if(this.platform.is('cordova')) {

        const hasPermission = this.androidPermissions.checkPermission(
          this.androidPermissions.PERMISSION.READ_PHONE_STATE
        );
        if (!hasPermission) {
          const result = this.androidPermissions.requestPermission(
            this.androidPermissions.PERMISSION.READ_PHONE_STATE
          );
        }

        this.network.onDisconnect().subscribe(() => {
          $("#offline .bt-fechar").attr('data-btmodal', "offline");
          $("#offline .bt-fechar").attr('data-animasi', "zoom");
          $("#offline").addClass('kecil');
          setTimeout(function(){
            $("#offline").removeClass('kecil');
            $("#offline").addClass('besar');
          }, 200);

          if(!$("#offline").hasClass("gaiso")){
            $("#offline").attr('data-btmodal', "offline");
            $("#offline").attr('data-animasi', 'zoom');
          }
        });

        this.fcm.onNotification().subscribe(data => {
          var dataku = JSON.stringify(data);
          var jsonobject = JSON.parse(dataku);
          if (data.wasTapped) {
            // console.log(JSON.stringify(data));
            $(".notifback").show();
          }
          else {
            $(".notifback").show();
            if(data.title == "Peringatan - Prowarga"){
              $("#multipledev .bt-fechar").attr('data-btmodal', "multipledev");
              $("#multipledev .bt-fechar").attr('data-animasi', "zoom");
              $("#multipledev").addClass('kecil');
              setTimeout(function(){
                $("#multipledev").removeClass('kecil');
                $("#multipledev").addClass('besar');
              }, 200);

              if(!$("#multipledev").hasClass("gaiso")){
                $("#multipledev").attr('data-btmodal', "multipledev");
                $("#multipledev").attr('data-animasi', 'zoom');
              }
              // localStorage.setItem('hapusuuid', jsonobject.data.hapus);
            }
            if(data.title == "Update - Prowarga"){
              $("#updateaplikasi .bt-fechar").attr('data-btmodal', "updateaplikasi");
              $("#updateaplikasi .bt-fechar").attr('data-animasi', "zoom");
              $("#updateaplikasi").addClass('kecil');
              setTimeout(function(){
                $("#updateaplikasi").removeClass('kecil');
                $("#updateaplikasi").addClass('besar');
              }, 200);

              if(!$("#updateaplikasi").hasClass("gaiso")){
                $("#updateaplikasi").attr('data-btmodal', "updateaplikasi");
                $("#updateaplikasi").attr('data-animasi', 'zoom');
              }
            }
            this.localNotifications.schedule({
              title       : data.title,
              smallIcon   : 'res://iconlama',
              icon        : 'res://icon.png',
              text        : data.body
            });
            this.localNotifications.on("click").subscribe(res =>{
              this.router.navigate(['notifikasi'], {replaceUrl: true});
            });
          };
        })
      }
    });
    if(localStorage.getItem('memberid') !== null ){

      // $.ajax({
      //   type: "get",
      //   url: global.apiURL+"cekstatus",
      //   data: {
      //   },
      //   success: (obj)=>{

      //   }
      // });


      if(this.location.path() == ''){
        localStorage.removeItem('provid');
        localStorage.removeItem('provname');
        localStorage.removeItem('kabid');
        localStorage.removeItem('kabname');
        localStorage.removeItem('kecid');
        localStorage.removeItem('kecname');
        localStorage.removeItem('kelid');
        localStorage.removeItem('kelname');

        localStorage.removeItem('type_id');
        localStorage.removeItem('rt');
        localStorage.removeItem('rw');
        localStorage.removeItem('nama');
        localStorage.removeItem('bank_id');
        localStorage.removeItem('slot_id');
        localStorage.removeItem('paket_id');
        localStorage.removeItem('namaslot');
        localStorage.removeItem('namatype');
        localStorage.removeItem('inv');
        if(localStorage.getItem('menu') !== null){
          this.router.navigate(['notifikasi'], {replaceUrl: true});
        }
        else{
          this.router.navigate(['personal']);
        }
      }
    }
    else{
      this.router.navigate(['home']);
    }

    this.platform.ready().then(() => {

      var that = this;
      setTimeout(function(){
        // alert("Hello");
        that.splashScreen.hide();
      }, 1000);
      this.statusBar.styleDefault();
      this.statusBar.show();
      // this.statusBar.backgroundColorByHexString("darkGray");
      this.statusBar.backgroundColorByHexString("F0F3F6");


    });
  }

}
