import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicStorageModule } from '@ionic/storage';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { HttpClientModule } from '@angular/common/http';
import { HTTP } from '@ionic-native/http/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';

import { registerLocaleData } from '@angular/common';
// import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import localeId from '@angular/common/locales/id';
registerLocaleData(localeId, 'id');

import { SplashScreen } from '@ionic-native/splash-screen/ngx';

import { NativePageTransitions } from '@ionic-native/native-page-transitions/ngx';

import { Camera }     from '@ionic-native/camera/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';

// Add the following properties to ionicModule in app.module.ts.
// IonicModule.forRoot(MyApp, {
// scrollAssist: false,
// autoFocusAssist: false
// })

// import { FCM } from '@ionic-native/fcm/ngx';

import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { File } from "@ionic-native/file/ngx";
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { Network } from '@ionic-native/network/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { PhotoViewer } from '@ionic-native/photo-viewer/ngx';

import { Downloader } from '@ionic-native/downloader/ngx';
import { Device } from '@ionic-native/device/ngx';
import { Contacts } from '@ionic-native/contacts/ngx';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    // CarouselModule,
    IonicModule.forRoot({
      scrollPadding: false,
      scrollAssist: false,
    }),
    IonicStorageModule.forRoot(),
    HttpClientModule,
    AppRoutingModule,
    ],

    providers: [
      FCM,
      Network,
      FirebaseAnalytics,
      File,
      Downloader,
      Contacts,
      Device,
      PhotoViewer,
      BackgroundMode,
      AndroidPermissions,
      FileTransfer,
      AppVersion,
      LocalNotifications,
      { provide: LOCALE_ID, useValue: "id-ID" },
      {
        provide: RouteReuseStrategy, useClass: IonicRouteStrategy,
      },
      HTTP,
      Camera,
      StatusBar,
      NativePageTransitions,
      FingerprintAIO,
      SplashScreen
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
