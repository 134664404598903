import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'masuk',
    loadChildren: () => import('./masuk/masuk.module').then( m => m.MasukPageModule)
  },
  {
    path: 'masuk/:page',
    loadChildren: () => import('./masuk/masuk.module').then( m => m.MasukPageModule)
  },
  {
    path: 'registrasi',
    loadChildren: () => import('./registrasi/registrasi.module').then( m => m.RegistrasiPageModule)
  },
  {
    path: 'registrasi/:page',
    loadChildren: () => import('./registrasi/registrasi.module').then( m => m.RegistrasiPageModule)
  },
  {
    path: 'personal',
    loadChildren: () => import('./personal/personal.module').then( m => m.PersonalPageModule)
  },
  {
    path: 'otp',
    loadChildren: () => import('./otp/otp.module').then( m => m.OtpPageModule)
  },
  {
    path: 'otp/:page',
    loadChildren: () => import('./otp/otp.module').then( m => m.OtpPageModule)
  },
  {
    path: 'pin',
    loadChildren: () => import('./pin/pin.module').then( m => m.PinPageModule)
  },
  {
    path: 'pin/:page',
    loadChildren: () => import('./pin/pin.module').then( m => m.PinPageModule)
  },
  {
    path: 'otpemail',
    loadChildren: () => import('./otpemail/otpemail.module').then( m => m.OtpemailPageModule)
  },
  {
    path: 'otpemail/:page',
    loadChildren: () => import('./otpemail/otpemail.module').then( m => m.OtpemailPageModule)
  },
  {
    path: 'pinmasuk',
    loadChildren: () => import('./pinmasuk/pinmasuk.module').then( m => m.PinmasukPageModule)
  },
  {
    path: 'pinmasuk/:page',
    loadChildren: () => import('./pinmasuk/pinmasuk.module').then( m => m.PinmasukPageModule)
  },
  {
    path: 'menu',
    loadChildren: () => import('./menu/menu.module').then( m => m.MenuPageModule)
  },
  {
    path: 'menu/:page',
    loadChildren: () => import('./menu/menu.module').then( m => m.MenuPageModule)
  },
  {
    path: 'menu/:page/:id',
    loadChildren: () => import('./menu/menu.module').then( m => m.MenuPageModule)
  },
  // {
  //   path: 'asetuser',
  //   loadChildren: () => import('./asetuser/asetuser.module').then( m => m.AsetuserPageModule)
  // },
  {
    path: 'asetuser/:page',
    loadChildren: () => import('./asetuser/asetuser.module').then( m => m.AsetuserPageModule)
  },
  {
    path: 'asetuser/:page/lingkungan/:id',
    loadChildren: () => import('./asetuser/asetuser.module').then( m => m.AsetuserPageModule)
  },
  {
    path: 'asetuser/:page/lingkungan/:id/:sort',
    loadChildren: () => import('./asetuser/asetuser.module').then( m => m.AsetuserPageModule)
  },
  {
    path: 'asetuser/:page/:sort',
    loadChildren: () => import('./asetuser/asetuser.module').then( m => m.AsetuserPageModule)
  },
  {
    path: 'pengelola',
    loadChildren: () => import('./pengelola/pengelola.module').then( m => m.PengelolaPageModule)
  },
  {
    path: 'pengelola/:page/:id',
    loadChildren: () => import('./pengelola/pengelola.module').then( m => m.PengelolaPageModule)
  },
  {
    path: 'pengelola/:page/:from/:id',
    loadChildren: () => import('./pengelola/pengelola.module').then( m => m.PengelolaPageModule)
  },
  {
    path: 'pengelola/:page/:from/:id/:ling',
    loadChildren: () => import('./pengelola/pengelola.module').then( m => m.PengelolaPageModule)
  },
  {
    path: 'blok',
    loadChildren: () => import('./blok/blok.module').then( m => m.BlokPageModule)
  },
  {
    path: 'blok/:id',
    loadChildren: () => import('./blok/blok.module').then( m => m.BlokPageModule)
  },
  {
    path: 'pilihkuponretribusi/:id/:from',
    loadChildren: () => import('./pilihkuponretribusi/pilihkuponretribusi.module').then( m => m.PilihkuponretribusiPageModule)
  },
  {
    path: 'pilihasetuser',
    loadChildren: () => import('./pilihasetuser/pilihasetuser.module').then( m => m.PilihasetuserPageModule)
  },
  {
    path: 'klaimretribusiuser/:id/:from',
    loadChildren: () => import('./klaimretribusiuser/klaimretribusiuser.module').then( m => m.KlaimretribusiuserPageModule)
  },
  {
    path: 'transaksiuser',
    loadChildren: () => import('./transaksiuser/transaksiuser.module').then( m => m.TransaksiuserPageModule)
  },
  {
    path: 'transaksiuser/:page',
    loadChildren: () => import('./transaksiuser/transaksiuser.module').then( m => m.TransaksiuserPageModule)
  },
  {
    path: 'transaksiuser/:page/:from',
    loadChildren: () => import('./transaksiuser/transaksiuser.module').then( m => m.TransaksiuserPageModule)
  },
  {
    path: 'moderasi',
    loadChildren: () => import('./moderasi/moderasi.module').then( m => m.ModerasiPageModule)
  },
  {
    path: 'moderasi/:id',
    loadChildren: () => import('./moderasi/moderasi.module').then( m => m.ModerasiPageModule)
  },
  {
    path: 'tambahketua',
    loadChildren: () => import('./tambahketua/tambahketua.module').then( m => m.TambahketuaPageModule)
  },
  {
    path: 'buatlingkungan',
    loadChildren: () => import('./buatlingkungan/buatlingkungan.module').then( m => m.BuatlingkunganPageModule)
  },
  {
    path: 'listinglokasi',
    loadChildren: () => import('./listinglokasi/listinglokasi.module').then( m => m.ListinglokasiPageModule)
  },
  {
    path: 'listinglokasi/:page',
    loadChildren: () => import('./listinglokasi/listinglokasi.module').then( m => m.ListinglokasiPageModule)
  },
  {
    path: 'listinglokasi/:page/:from/:id',
    loadChildren: () => import('./listinglokasi/listinglokasi.module').then( m => m.ListinglokasiPageModule)
  },
  {
    path: 'pilihpaketberlangganan',
    loadChildren: () => import('./pilihpaketberlangganan/pilihpaketberlangganan.module').then( m => m.PilihpaketberlanggananPageModule)
  },
  {
    path: 'pilihpaketberlangganan/:id',
    loadChildren: () => import('./pilihpaketberlangganan/pilihpaketberlangganan.module').then( m => m.PilihpaketberlanggananPageModule)
  },
  {
    path: 'metodepembayaranprowarga',
    loadChildren: () => import('./metodepembayaranprowarga/metodepembayaranprowarga.module').then( m => m.MetodepembayaranprowargaPageModule)
  },
  {
    path: 'reviewtransaksiprowargauser',
    loadChildren: () => import('./reviewtransaksiprowargauser/reviewtransaksiprowargauser.module').then( m => m.ReviewtransaksiprowargauserPageModule)
  },
  {
    path: 'reviewtransaksiprowargauser/:page',
    loadChildren: () => import('./reviewtransaksiprowargauser/reviewtransaksiprowargauser.module').then( m => m.ReviewtransaksiprowargauserPageModule)
  },
  {
    path: 'reviewtransaksiprowargauser/:page/:id',
    loadChildren: () => import('./reviewtransaksiprowargauser/reviewtransaksiprowargauser.module').then( m => m.ReviewtransaksiprowargauserPageModule)
  },
  {
    path: 'alihkanmoderasi',
    loadChildren: () => import('./alihkanmoderasi/alihkanmoderasi.module').then( m => m.AlihkanmoderasiPageModule)
  },
  {
    path: 'notifikasi/:from',
    loadChildren: () => import('./notifikasi/notifikasi.module').then( m => m.NotifikasiPageModule)
  },
  {
    path: 'notifikasi/:from/:id',
    loadChildren: () => import('./notifikasi/notifikasi.module').then( m => m.NotifikasiPageModule)
  },
  {
    path: 'tugas',
    loadChildren: () => import('./tugas/tugas.module').then( m => m.TugasPageModule)
  },
  {
    path: 'dashboardlingkungan/:id',
    loadChildren: () => import('./dashboardlingkungan/dashboardlingkungan.module').then( m => m.DashboardlingkunganPageModule)
  },
  {
    path: 'pengelolalingkungan/:id',
    loadChildren: () => import('./pengelolalingkungan/pengelolalingkungan.module').then( m => m.PengelolalingkunganPageModule)
  },
  {
    path: 'persetujuanlingkungan',
    loadChildren: () => import('./persetujuanlingkungan/persetujuanlingkungan.module').then( m => m.PersetujuanlingkunganPageModule)
  },
  {
    path: 'persetujuanlingkungan/:id',
    loadChildren: () => import('./persetujuanlingkungan/persetujuanlingkungan.module').then( m => m.PersetujuanlingkunganPageModule)
  },
  {
    path: 'lainnyauser',
    loadChildren: () => import('./lainnyauser/lainnyauser.module').then( m => m.LainnyauserPageModule)
  },
  {
    path: 'ketentuanlayanan',
    loadChildren: () => import('./ketentuanlayanan/ketentuanlayanan.module').then( m => m.KetentuanlayananPageModule)
  },
  {
    path: 'kebijakanprivasi',
    loadChildren: () => import('./kebijakanprivasi/kebijakanprivasi.module').then( m => m.KebijakanprivasiPageModule)
  },
  {
    path: 'bantuan',
    loadChildren: () => import('./bantuan/bantuan.module').then( m => m.BantuanPageModule)
  },
  {
    path: 'buataset/:id',
    loadChildren: () => import('./buataset/buataset.module').then( m => m.BuatasetPageModule)
  },
  {
    path: 'buataset/:id/:edit',
    loadChildren: () => import('./buataset/buataset.module').then( m => m.BuatasetPageModule)
  },
  {
    path: 'pilihkategoribuataset/:id',
    loadChildren: () => import('./pilihkategoribuataset/pilihkategoribuataset.module').then( m => m.PilihkategoribuatasetPageModule)
  },
  {
    path: 'buatkategoriaset',
    loadChildren: () => import('./buatkategoriaset/buatkategoriaset.module').then( m => m.BuatkategoriasetPageModule)
  },
  {
    path: 'buatkategoriaset/:id',
    loadChildren: () => import('./buatkategoriaset/buatkategoriaset.module').then( m => m.BuatkategoriasetPageModule)
  },
  {
    path: 'buatkategoriaset/:id/:from',
    loadChildren: () => import('./buatkategoriaset/buatkategoriaset.module').then( m => m.BuatkategoriasetPageModule)
  },
  {
    path: 'buatkategoriaset/:id/:edit/:from',
    loadChildren: () => import('./buatkategoriaset/buatkategoriaset.module').then( m => m.BuatkategoriasetPageModule)
  },
  {
    path: 'cashflowlingkungan',
    loadChildren: () => import('./cashflowlingkungan/cashflowlingkungan.module').then( m => m.CashflowlingkunganPageModule)
  },
  {
    path: 'cashflowlingkungan/:id',
    loadChildren: () => import('./cashflowlingkungan/cashflowlingkungan.module').then( m => m.CashflowlingkunganPageModule)
  },
  {
    path: 'tahuncashflowlingkungan',
    loadChildren: () => import('./tahuncashflowlingkungan/tahuncashflowlingkungan.module').then( m => m.TahuncashflowlingkunganPageModule)
  },
  {
    path: 'tahuncashflowlingkungan/:id',
    loadChildren: () => import('./tahuncashflowlingkungan/tahuncashflowlingkungan.module').then( m => m.TahuncashflowlingkunganPageModule)
  },
  {
    path: 'transaksilingkungan/:id',
    loadChildren: () => import('./transaksilingkungan/transaksilingkungan.module').then( m => m.TransaksilingkunganPageModule)
  },
  {
    path: 'invoice/:ling/:id',
    loadChildren: () => import('./invoice/invoice.module').then( m => m.InvoicePageModule)
  },
  {
    path: 'invoice/:ling/:id/:page',
    loadChildren: () => import('./invoice/invoice.module').then( m => m.InvoicePageModule)
  },
  {
    path: 'statistikasetlingkungan',
    loadChildren: () => import('./statistikasetlingkungan/statistikasetlingkungan.module').then( m => m.StatistikasetlingkunganPageModule)
  },
  {
    path: 'statistikasetlingkungan/:id',
    loadChildren: () => import('./statistikasetlingkungan/statistikasetlingkungan.module').then( m => m.StatistikasetlingkunganPageModule)
  },
  {
    path: 'statistikasetlingkungan/:id/:from/:ling',
    loadChildren: () => import('./statistikasetlingkungan/statistikasetlingkungan.module').then( m => m.StatistikasetlingkunganPageModule)
  },
  {
    path: 'lainnyalingkungan/:id',
    loadChildren: () => import('./lainnyalingkungan/lainnyalingkungan.module').then( m => m.LainnyalingkunganPageModule)
  },
  {
    path: 'retribusilingkungan/:id',
    loadChildren: () => import('./retribusilingkungan/retribusilingkungan.module').then( m => m.RetribusilingkunganPageModule)
  },
  {
    path: 'retribusilingkungan/:id/:tahun',
    loadChildren: () => import('./retribusilingkungan/retribusilingkungan.module').then( m => m.RetribusilingkunganPageModule)
  },
  {
    path: 'kategoriaset/:id',
    loadChildren: () => import('./kategoriaset/kategoriaset.module').then( m => m.KategoriasetPageModule)
  },
  {
    path: 'kategoriaset/:id/:from',
    loadChildren: () => import('./kategoriaset/kategoriaset.module').then( m => m.KategoriasetPageModule)
  },
  {
    path: 'kategoriaset/:id/:edit/:from',
    loadChildren: () => import('./kategoriaset/kategoriaset.module').then( m => m.KategoriasetPageModule)
  },
  {
    path: 'sektorlingkungan/:id',
    loadChildren: () => import('./sektorlingkungan/sektorlingkungan.module').then( m => m.SektorlingkunganPageModule)
  },
  {
    path: 'pengaturanlingkungan/:id',
    loadChildren: () => import('./pengaturanlingkungan/pengaturanlingkungan.module').then( m => m.PengaturanlingkunganPageModule)
  },
  {
    path: 'editprofillingkungan/:id',
    loadChildren: () => import('./editprofillingkungan/editprofillingkungan.module').then( m => m.EditprofillingkunganPageModule)
  },
  {
    path: 'tahunretribusi',
    loadChildren: () => import('./tahunretribusi/tahunretribusi.module').then( m => m.TahunretribusiPageModule)
  },
  {
    path: 'tahunretribusi/:id',
    loadChildren: () => import('./tahunretribusi/tahunretribusi.module').then( m => m.TahunretribusiPageModule)
  },
  {
    path: 'pengaturanuser',
    loadChildren: () => import('./pengaturanuser/pengaturanuser.module').then( m => m.PengaturanuserPageModule)
  },
  {
    path: 'editprofiluser',
    loadChildren: () => import('./editprofiluser/editprofiluser.module').then( m => m.EditprofiluserPageModule)
  },
  {
    path: 'tambahkanrekening',
    loadChildren: () => import('./tambahkanrekening/tambahkanrekening.module').then( m => m.TambahkanrekeningPageModule)
  },
  {
    path: 'tambahkanrekening/:id',
    loadChildren: () => import('./tambahkanrekening/tambahkanrekening.module').then( m => m.TambahkanrekeningPageModule)
  },
  {
    path: 'tambahkanrekening/:page/:id',
    loadChildren: () => import('./tambahkanrekening/tambahkanrekening.module').then( m => m.TambahkanrekeningPageModule)
  },
  {
    path: 'metodepembayaran',
    loadChildren: () => import('./metodepembayaran/metodepembayaran.module').then( m => m.MetodepembayaranPageModule)
  },
  {
    path: 'metodepembayaran/:page',
    loadChildren: () => import('./metodepembayaran/metodepembayaran.module').then( m => m.MetodepembayaranPageModule)
  },
  {
    path: 'metodepembayaran/:page/:id',
    loadChildren: () => import('./metodepembayaran/metodepembayaran.module').then( m => m.MetodepembayaranPageModule)
  },
  {
    path: 'invoicelingkungan',
    loadChildren: () => import('./invoicelingkungan/invoicelingkungan.module').then( m => m.InvoicelingkunganPageModule)
  },
  {
    path: 'invoicelingkungan/:id',
    loadChildren: () => import('./invoicelingkungan/invoicelingkungan.module').then( m => m.InvoicelingkunganPageModule)
  },
  {
    path: 'invoicelingkungan/:page/:id',
    loadChildren: () => import('./invoicelingkungan/invoicelingkungan.module').then( m => m.InvoicelingkunganPageModule)
  },
  {
    path: 'invoicelingkungan/:page/:id/:from',
    loadChildren: () => import('./invoicelingkungan/invoicelingkungan.module').then( m => m.InvoicelingkunganPageModule)
  },
  {
    path: 'invoicelingkungan/:page/:id/:from/:ling',
    loadChildren: () => import('./invoicelingkungan/invoicelingkungan.module').then( m => m.InvoicelingkunganPageModule)
  },
  {
    path: 'ovo',
    loadChildren: () => import('./ovo/ovo.module').then( m => m.OvoPageModule)
  },
  {
    path: 'ovo/:id',
    loadChildren: () => import('./ovo/ovo.module').then( m => m.OvoPageModule)
  },
  {
    path: 'pengurus',
    loadChildren: () => import('./pengurus/pengurus.module').then( m => m.PengurusPageModule)
  },
  {
    path: 'pengurus/:page/:pangkat/:id',
    loadChildren: () => import('./pengurus/pengurus.module').then( m => m.PengurusPageModule)
  },
  {
    path: 'pengurus/:page/:pangkat/:id/:from',
    loadChildren: () => import('./pengurus/pengurus.module').then( m => m.PengurusPageModule)
  },
  {
    path: 'dashboardprotunai',
    loadChildren: () => import('./dashboardprotunai/dashboardprotunai.module').then( m => m.DashboardprotunaiPageModule)
  },
  {
    path: 'topup',
    loadChildren: () => import('./topup/topup.module').then( m => m.TopupPageModule)
  },
  {
    path: 'info',
    loadChildren: () => import('./info/info.module').then( m => m.InfoPageModule)
  },
  {
    path: 'info/:page',
    loadChildren: () => import('./info/info.module').then( m => m.InfoPageModule)
  },
  {
    path: 'info/:page/:from',
    loadChildren: () => import('./info/info.module').then( m => m.InfoPageModule)
  },
  {
    path: 'info/:page/:from/:hal/:id',
    loadChildren: () => import('./info/info.module').then( m => m.InfoPageModule)
  },
  {
    path: 'info/:page/:from/:id',
    loadChildren: () => import('./info/info.module').then( m => m.InfoPageModule)
  },
  {
    path: 'transaksimoderasi',
    loadChildren: () => import('./transaksimoderasi/transaksimoderasi.module').then( m => m.TransaksimoderasiPageModule)
  },
  {
    path: 'transaksimoderasi/:status',
    loadChildren: () => import('./transaksimoderasi/transaksimoderasi.module').then( m => m.TransaksimoderasiPageModule)
  },
  {
    path: 'transaksimoderasi/:status/:tahun',
    loadChildren: () => import('./transaksimoderasi/transaksimoderasi.module').then( m => m.TransaksimoderasiPageModule)
  },
  {
    path: 'transfer',
    loadChildren: () => import('./transfer/transfer.module').then( m => m.TransferPageModule)
  },
  {
    path: 'transaksiprotunai',
    loadChildren: () => import('./transaksiprotunai/transaksiprotunai.module').then( m => m.TransaksiprotunaiPageModule)
  },
  {
    path: 'transaksiprotunai/:page',
    loadChildren: () => import('./transaksiprotunai/transaksiprotunai.module').then( m => m.TransaksiprotunaiPageModule)
  },
  {
    path: 'tariktunai',
    loadChildren: () => import('./tariktunai/tariktunai.module').then( m => m.TariktunaiPageModule)
  },
  {
    path: 'inputemail',
    loadChildren: () => import('./inputemail/inputemail.module').then( m => m.InputemailPageModule)
  },
  {
    path: 'inputemail/:page',
    loadChildren: () => import('./inputemail/inputemail.module').then( m => m.InputemailPageModule)
  },
  {
    path: 'virtualaccount/:page/:id',
    loadChildren: () => import('./virtualaccount/virtualaccount.module').then( m => m.VirtualaccountPageModule)
  },
  {
    path: 'virtualaccount/:page/:id/:from',
    loadChildren: () => import('./virtualaccount/virtualaccount.module').then( m => m.VirtualaccountPageModule)
  },
  {
    path: 'menungguvalidasi',
    loadChildren: () => import('./menungguvalidasi/menungguvalidasi.module').then( m => m.MenungguvalidasiPageModule)
  },
  {
    path: 'rekeningdetail/:id',
    loadChildren: () => import('./rekeningdetail/rekeningdetail.module').then( m => m.RekeningdetailPageModule)
  },
  {
    path: 'infopengelola/:id',
    loadChildren: () => import('./infopengelola/infopengelola.module').then( m => m.InfopengelolaPageModule)
  },
  {
    path: 'hotline/:id',
    loadChildren: () => import('./hotline/hotline.module').then( m => m.HotlinePageModule)
  },
  {
    path: 'dashboardaset/:id',
    loadChildren: () => import('./dashboardasetlingkungan/dashboardasetlingkungan.module').then( m => m.DashboardasetlingkunganPageModule)
  },
  {
    path: 'dashboardasetlingkungan/:id/:ling',
    loadChildren: () => import('./dashboardasetlingkungan/dashboardasetlingkungan.module').then( m => m.DashboardasetlingkunganPageModule)
  },
  {
    path: 'transaksimenunggulingkungan/:id',
    loadChildren: () => import('./transaksimenunggulingkungan/transaksimenunggulingkungan.module').then( m => m.TransaksimenunggulingkunganPageModule)
  },
  {
    path: 'transaksimenunggulingkungan/:id/:status',
    loadChildren: () => import('./transaksimenunggulingkungan/transaksimenunggulingkungan.module').then( m => m.TransaksimenunggulingkunganPageModule)
  },
  {
    path: 'notifikasilingkungan/:id',
    loadChildren: () => import('./notifikasilingkungan/notifikasilingkungan.module').then( m => m.NotifikasilingkunganPageModule)
  },
  {
    path: 'tariktunailingkungan/:id',
    loadChildren: () => import('./tariktunailingkungan/tariktunailingkungan.module').then( m => m.TariktunailingkunganPageModule)
  },
  {
    path: 'transaksiprotunailingkungan/:id',
    loadChildren: () => import('./transaksiprotunailingkungan/transaksiprotunailingkungan.module').then( m => m.TransaksiprotunailingkunganPageModule)
  },
  {
    path: 'kuponaset/:id',
    loadChildren: () => import('./kuponasetlingkungan/kuponasetlingkungan.module').then( m => m.KuponasetlingkunganPageModule)
  },
  {
    path: 'kuponasetlingkungan/:id/:ling',
    loadChildren: () => import('./kuponasetlingkungan/kuponasetlingkungan.module').then( m => m.KuponasetlingkunganPageModule)
  },
  {
    path: 'kuponasetlingkungan/:id/:ling/:status',
    loadChildren: () => import('./kuponasetlingkungan/kuponasetlingkungan.module').then( m => m.KuponasetlingkunganPageModule)
  },
  {
    path: 'transaksiasetuser/:id',
    loadChildren: () => import('./transaksiaset/transaksiaset.module').then( m => m.TransaksiasetPageModule)
  },
  {
    path: 'transaksiasetuser/:id/:status',
    loadChildren: () => import('./transaksiaset/transaksiaset.module').then( m => m.TransaksiasetPageModule)
  },
  {
    path: 'transaksiaset/:id/:ling',
    loadChildren: () => import('./transaksiaset/transaksiaset.module').then( m => m.TransaksiasetPageModule)
  },
  {
    path: 'transaksiaset/:id/:ling/:status',
    loadChildren: () => import('./transaksiaset/transaksiaset.module').then( m => m.TransaksiasetPageModule)
  },
  {
    path: 'penanggungjawabaset/:id/:ling',
    loadChildren: () => import('./penanggungjawabaset/penanggungjawabaset.module').then( m => m.PenanggungjawabasetPageModule)
  },
  {
    path: 'tambahpenanggungjawabaset/:id/:ling',
    loadChildren: () => import('./tambahpenanggungjawabaset/tambahpenanggungjawabaset.module').then( m => m.TambahpenanggungjawabasetPageModule)
  },
  {
    path: 'klaimretribusilingkungan/:id/:ling/:from',
    loadChildren: () => import('./klaimretribusilingkungan/klaimretribusilingkungan.module').then( m => m.KlaimretribusilingkunganPageModule)
  },
  {
    path: 'klaimretribusilingkungan/:id/:ling/:from/:inv',
    loadChildren: () => import('./klaimretribusilingkungan/klaimretribusilingkungan.module').then( m => m.KlaimretribusilingkunganPageModule)
  },
  {
    path: 'reviewinvoicelingkunganretribusiklaim/:id/:ling',
    loadChildren: () => import('./reviewinvoicelingkunganretribusiklaim/reviewinvoicelingkunganretribusiklaim.module').then( m => m.ReviewinvoicelingkunganretribusiklaimPageModule)
  },
  {
    path: 'riwayatkategoriaset/:id/:ling',
    loadChildren: () => import('./riwayatkategoriaset/riwayatkategoriaset.module').then( m => m.RiwayatkategoriasetPageModule)
  },
  {
    path: 'bukakupon/:tgl/:id/:ling',
    loadChildren: () => import('./bukakupon/bukakupon.module').then( m => m.BukakuponPageModule)
  },
  {
    path: 'buatcashflow/:ling',
    loadChildren: () => import('./buatcashflow/buatcashflow.module').then( m => m.BuatcashflowPageModule)
  },
  {
    path: 'detailcashflowuser/:tgl/:ling/:id',
    loadChildren: () => import('./detailcashflow/detailcashflow.module').then( m => m.DetailcashflowPageModule)
  },
  {
    path: 'detailcashflowuser/:tgl/:ling/:from/:sektor',
    loadChildren: () => import('./detailcashflow/detailcashflow.module').then( m => m.DetailcashflowPageModule)
  },
  {
    path: 'detailcashflow/:tgl/:ling',
    loadChildren: () => import('./detailcashflow/detailcashflow.module').then( m => m.DetailcashflowPageModule)
  },
  {
    path: 'cashflowretribusi/:tgl/:ling',
    loadChildren: () => import('./cashflowretribusi/cashflowretribusi.module').then( m => m.CashflowretribusiPageModule)
  },
  {
    path: 'detailcashflowretribusi/:id/:tgl/:ling',
    loadChildren: () => import('./detailcashflowretribusi/detailcashflowretribusi.module').then( m => m.DetailcashflowretribusiPageModule)
  },
  {
    path: 'statistikcashflow/:tgl/:ling',
    loadChildren: () => import('./statistikcashflow/statistikcashflow.module').then( m => m.StatistikcashflowPageModule)
  },
  {
    path: 'detailcashflowlingkungan/:tgl/:ling',
    loadChildren: () => import('./detailcashflowlingkungan/detailcashflowlingkungan.module').then( m => m.DetailcashflowlingkunganPageModule)
  },
  {
    path: 'tambahkanuser/:id/:ling',
    loadChildren: () => import('./tambahkanuser/tambahkanuser.module').then( m => m.TambahkanuserPageModule)
  },
  {
    path: 'informasilingkungan/:id',
    loadChildren: () => import('./informasilingkungan/informasilingkungan.module').then( m => m.InformasilingkunganPageModule)
  },
  {
    path: 'spesialakses/:ling',
    loadChildren: () => import('./spesialakses/spesialakses.module').then( m => m.SpesialaksesPageModule)
  },
  {
    path: 'pilihkupontersedia/:id/:from',
    loadChildren: () => import('./pilihkupontersedia/pilihkupontersedia.module').then( m => m.PilihkupontersediaPageModule)
  },
  {
    path: 'riwayatsaldotertundalingkungan/:id',
    loadChildren: () => import('./riwayatsaldotertundalingkungan/riwayatsaldotertundalingkungan.module').then( m => m.RiwayatsaldotertundalingkunganPageModule)
  },
  {
    path: 'detailinfo/:page/:id/:from',
    loadChildren: () => import('./detailinfo/detailinfo.module').then( m => m.DetailinfoPageModule)
  },
  {
    path: 'detailinfo/:page/:id/:from/:ling',
    loadChildren: () => import('./detailinfo/detailinfo.module').then( m => m.DetailinfoPageModule)
  },
  {
    path: 'lingkungansektor/:id',
    loadChildren: () => import('./lingkungansektor/lingkungansektor.module').then( m => m.LingkungansektorPageModule)
  },
  {
    path: 'tambahkanlingkungan/:id',
    loadChildren: () => import('./tambahkanlingkungan/tambahkanlingkungan.module').then( m => m.TambahkanlingkunganPageModule)
  },
  {
    path: 'kuponprovaganza',
    loadChildren: () => import('./kuponprovaganza/kuponprovaganza.module').then( m => m.KuponprovaganzaPageModule)
  },
  {
    path: 'kuponprovaganza/:from',
    loadChildren: () => import('./kuponprovaganza/kuponprovaganza.module').then( m => m.KuponprovaganzaPageModule)
  },
  {
    path: 'masaberlangganan',
    loadChildren: () => import('./masaberlangganan/masaberlangganan.module').then( m => m.MasaberlanggananPageModule)
  },
  {
    path: 'masaberlangganan/:id',
    loadChildren: () => import('./masaberlangganan/masaberlangganan.module').then( m => m.MasaberlanggananPageModule)
  },
  {
    path: 'pemenangprovaganza',
    loadChildren: () => import('./pemenangprovaganza/pemenangprovaganza.module').then( m => m.PemenangprovaganzaPageModule)
  },
  {
    path: 'panduanbayar',
    loadChildren: () => import('./panduanbayar/panduanbayar.module').then( m => m.PanduanbayarPageModule)
  },
  {
    path: 'spesialaksessektor/:id/:ling',
    loadChildren: () => import('./spesialaksessektor/spesialaksessektor.module').then( m => m.SpesialaksessektorPageModule)
  },
  {
    path: 'transferkontribusi/:id/:ling',
    loadChildren: () => import('./transferkontribusi/transferkontribusi.module').then( m => m.TransferkontribusiPageModule)
  },
  {
    path: 'transferkontribusi/:id/:ling/:from',
    loadChildren: () => import('./transferkontribusi/transferkontribusi.module').then( m => m.TransferkontribusiPageModule)
  },
  {
    path: 'sukses',
    loadChildren: () => import('./sukses/sukses.module').then( m => m.SuksesPageModule)
  },
  {
    path: 'sukses/:page/:id',
    loadChildren: () => import('./sukses/sukses.module').then( m => m.SuksesPageModule)
  },
  {
    path: 'sukses/:page/:id/:from',
    loadChildren: () => import('./sukses/sukses.module').then( m => m.SuksesPageModule)
  },
  {
    path: 'sukses/:page/:id/:from/:ling',
    loadChildren: () => import('./sukses/sukses.module').then( m => m.SuksesPageModule)
  },

  // 'invoicelingkungan/:page/:id/:from/:ling'
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
